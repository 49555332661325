<template>
  <div id="parentsCarousel">
    <div class="column-round">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="column-round-body">
        <div class="carousel">
          <a class="browse prev left play-left"></a>
          <div class="scrollable">
            <div class="items">
              <div>
                  <!-- UniverseParentsAccoladesAwards/images/picB5C3B0D88048D8456D436ECDF6E6F6C5.png -->
                <img
                  @click="open(195596)"
                  src="@/assets/parents/accolades-awards/picb5c3b0d88048d8456d436ecdf6e6f6c5.png"
                  alt="ZAM Award"
                />
              </div>

              <div>
                  <!-- UniverseParentsAccoladesAwards/images/pic4B70273A8D20365096B7B34A01F98B78.png -->
                <img
                  src="@/assets/parents/accolades-awards/pic4b70273a8d20365096b7b34a01f98b78.png"
                  alt="Boy's Life award"
                  @click="open(195597)"
                />
              </div>

              <div>
                  <!-- UniverseParentsAccoladesAwards/images/pic61BE2DB6793B6415319C0554944973D5.png -->
                <img
                  src="@/assets/parents/accolades-awards/pic61be2db6793b6415319c0554944973d5.png"
                  alt="Massively award"
                  @click="open(195598)"
                />
              </div>

              <div>
                  <!-- UniverseParentsAccoladesAwards/images/pic05F13FF1F05BBBFA0E80A220CD4209B3.png -->
                <img
                  src="@/assets/parents/accolades-awards/pic05f13ff1f05bbbfa0e80a220cd4209b3.png"
                  alt="Massively Award"
                  @click="open(195599)"
                />
              </div>

              <div>
                  <!-- UniverseParentsAccoladesAwards/images/pic8F01A90E16197D7F18E69585648BC3E0.jpg -->
                <img
                  src="@/assets/parents/accolades-awards/pic8f01a90e16197d7f18e69585648bc3e0.jpg"
                  alt="1UP Award"
                  @click="open(195600)"
                />
              </div>
            </div>
          </div>
          <a class="browse next right play-right"></a>
        </div>
      </div>
    </div>
    <div class="column-round column-round-flow last" ref="details" style="display: none;">
      <b><i></i></b><i><b></b></i><em></em>
      <div class="column-round-body">
        <div class="carouselContent" id="contentId195596">
          <span class="carouselDate small">ZAM, June 29, 2010</span>
          <h4>Best Family Game</h4>
          <p class="carouselText">
            When we first started discussing this award, we called this the Best
            Babysitter, because LEGO Universe is designed for the kids. But
            because the depth and replayability will appeal to the adults (and
            their fond memories of playing LEGOs as a kid), we figured Best
            Family Game was more appropriate, especially since the developers
            are working hard to make this a game where parents who play games
            can introduce their kids to an MMO where they have no concern over
            questionable content.<br /><br />The game is designed where players
            can only play good, and the AI is evil. And what's a LEGO game
            without having the ability to build your own play area where others
            can visit? LEGO Universe has it all at the moment, and we can only
            hope the game is as good when it is released as it showed at E3.<br />
          </p>
          <p class="carouselAuthor">ZAM, Darkartiste</p>
        </div>

        <div class="carouselContent" id="contentId195597">
          <span class="carouselDate small">Boy's Life, June 18, 2010</span>
          <h4>Best Kids MMO Ever</h4>
          <p class="carouselText">
            Speaking of deep, this is one deep game. You can make any world you
            want to with LEGO blocks, and every block ever made is in the game.
            It’s going to be updated every month with new quests and minigames.
            You won’t want to leave this game, but you probably should
            eventually, especially if you have homework to do.
          </p>
          <p class="carouselAuthor">Boy's Life, The Games Guru</p>
        </div>

        <div class="carouselContent" id="contentId195598">
          <span class="carouselDate small">Massively, June 19, 2010</span>
          <h4>Best MMO of E3 2010</h4>
          <p class="carouselText">
            “Not only did we think LEGO Universe was the best new MMO at E3, but
            even among its already-released peers, it has proven itself a star.
            A star made of little yellow LEGO bricks, but still a star. This was
            one where word quickly spread among those Massively staff members in
            attendance that making time for LEGO Universe should be a priority.
            Many of us tried it out and we all loved it. It's fun, it's creative
            and it's exactly what any LEGO fan would want in an MMO. Heck, it's
            exactly what any MMO fan would want in an MMO.”
          </p>
          <p class="carouselAuthor">Massively, Shawn Schuster</p>
        </div>

        <div class="carouselContent" id="contentId195599">
          <span class="carouselDate small">Massively, June 19, 2010</span>
          <h4>Best New MMO of E3 2010</h4>
          <p class="carouselText">
            As an overall experience, LEGO Universe offers more than anything
            else we saw at E3. Among new and upcoming games, it especially shown
            through.
          </p>
          <p class="carouselAuthor">Massively, Shawn Schuster</p>
        </div>

        <div class="carouselContent" id="contentId195600">
          <span class="carouselDate small">1UP, June 18, 2010</span>
          <h4>Best MMO of E3 2010</h4>
          <p class="carouselText">
            Surprised? Don’t be. Though Star Wars: The Old Republic certainly
            took the cake for role-playing elements, Lego Universe wowed us with
            its in-game building features. Not only can players interact with
            the whimsical Lego world fashioned by the developers, but they can
            also claim empty properties on which to build their own blocky
            creations, including creatures complete with customizable A.I.,
            which sets this MMO apart from any other on the list.
          </p>
          <p class="carouselAuthor">1UP, 1UP Staff</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
    setup() {
        const details = ref();
        const current = ref();

        function open(id: number) {
            const e = details.value as HTMLElement;
            e.style.display = "block";
            const sel = `#contentId${id}`;
            console.log(sel);
            const match = e.querySelector(sel);
            console.log("match", match);
            if (match) {
                const c = match as HTMLElement;
                console.log("current", current.value);
                c.style.display = "block";
                
                if (current.value && current.value.id != c.id) {
                    current.value.style.removeProperty('display');
                }
                current.value = c;
            }
        }

        return { open, details }
    },
})
</script>
