<template>
  <text-module>
    <div class="t-list" id="SafetyTipsList">
      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl00_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/picb9b837a15d886405de88b817fe839998.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              “Think of it as the online equivalent of the LEGO playtime you may
              have enjoyed as a kid, with the ability to imagine almost any
              creation and see it realized on screen. Plus, you’re able to
              inhabit and enjoy your own created LEGO corner with any friends
              you see fit to invite into your customized world.From what we’ve
              seen of the game so far, developer NetDevil have done a superb job
              staying true to the spirit and mentality of the LEGO brand while
              simultaneously creating a new virtual online version of a
              franchise many of us have known and loved since childhood. LEGO
              Universe is a family-friendly title that we suspect will end up
              captivating a good chunk of adult players along with the more
              obvious younger demographic of brick enthusiasts.”<br /><span
                class="recog-by"
                >Barb Dybwad, Mashable / June 21, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl01_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/picc2755e3ecd9ea37129c57a5dad31b2f9.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              “While E3 offers a glimpse into the imaginations of many
              developers, one game stands out as focusing on the imagination and
              creativity of the user: LEGO Universe. In fact, creativity and
              imagination are the focus of this game, literally… Young or old,
              if you enjoy stretching your imagination and cracking those
              creative knuckles, LEGO Universe is worth look. Very few -- if any
              -- other titles out there give you the creative freedom offered
              here, and security measures make it safe for family playing.”<br /><br /><span
                class="recog-by"
                >MJ Guthrie, Massively / June 18, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl02_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/pic63ddb77e8f5bd95dde915842adbded65.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              "It has every kind of LEGO brick ever made featured in it, and the
              way it allows you to build with them is so advanced that you can
              even follow real-world LEGO instructions to build objects… Even
              though you may look at it and think it’s aimed squarely at kids
              because it’s a game where toys are brought to life, it’s actually
              a full-on MMO with some incredibly in-depth features. If you have
              any interest in LEGO, it seems that there’s something in this game
              for you."<br /><span class="recog-by"
                >John Davison, GamePro / June 17, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl03_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/pic8fb56be9fefdd5650c6aff9d9d56b15b.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              "More than anything, LEGO Universe is my pick for the Best of Show
              because it reignited in me a feeling that video games have left
              dormant in me for so very, very long: imagination. I can’t wait to
              create a whole new set of memories of playing with digital LEGOs
              for hours, creating huge worlds out of bricks and bringing them to
              life."<br /><span class="recog-by"
                >Josh Augustine, PC Gamer / June 16, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl04_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/picf696d636a5a7bc086d548c9022113882.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              "The big winners here? The upcoming LEGO Universe has enchanted
              everyone who sees it … It takes you, as a creative spark
              inhabiting the body of a Minifigure, to build up the world,
              strengthen your imagination, and bring order to the land. The game
              is dripping with personality, and the ability to build anything
              you can dream and then give those creations life is a powerful
              motivator. This is one to watch."<br /><span class="recog-by"
                >Ben Kuchera, Ars Technica / January 16, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl05_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/picb45497c723de8149a5f18bb14de489b0.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              "Part virtual world, part LEGO action game and part creativity
              software, LEGO Universe looks like it will be worth the wait…the
              game is designed without levels so kids (and adults!) can explore
              at will, choosing which themed areas to enjoy…Personally, I think
              the game would do very well on the consoles as it looked gorgeous
              (even in Alpha) on the big screen."<br /><span class="recog-by"
                >Christy Matte, About.com / January 9, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl06_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/pic65fbfc56b6d07b251965ec9a17821277.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              “I could probably ramble on for a few more paragraphs about the
              fantastic assortment of minigames available, or the adorable
              subtleties of the animations, or the pets and their respective
              skills and utilities (they can even help you find treasure!).
              However, I think I'll conclude by asserting that LEGO truly has a
              winner on their hands-this game will be a phenomenal joy to both
              the young and the young at heart.”<br /><br /><br /><span
                class="recog-by"
                >Charlotte Tarlitz, Icrontic / January 9, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>

      <div class="t-row">
        <b><i></i></b><i><b></b></i><em></em>
        <div class="t-cell cell-listitem">
          <ul class="parents-list recognition">
            <li
              id="ctl00_ContentPlaceHolderUniverse_ContentAdminRepeater1_ctl07_ctl00_ExternalImage"
              class="parents-image"
            >
              <img
                src="@/assets/parents/accolades/picb3a7e447322f86793511f0daf3964c52.png"
                border="0"
              />
            </li>
            <li class="parents-text">
              “Basically, this is the natural evolution of the Lego brand. You
              know how you fondly remember playing with Lego sets when you were
              a kid? Your kids are going to remember this.”<br /><br /><br /><span
                class="recog-by"
                >Jason Chen, Gizmodo / January 8, 2010</span
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
  </text-module>
</template>