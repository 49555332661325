
import { defineComponent, ref } from 'vue'

export default defineComponent({
    setup() {
        const details = ref();
        const current = ref();

        function open(id: number) {
            const e = details.value as HTMLElement;
            e.style.display = "block";
            const sel = `#contentId${id}`;
            console.log(sel);
            const match = e.querySelector(sel);
            console.log("match", match);
            if (match) {
                const c = match as HTMLElement;
                console.log("current", current.value);
                c.style.display = "block";
                
                if (current.value && current.value.id != c.id) {
                    current.value.style.removeProperty('display');
                }
                current.value = c;
            }
        }

        return { open, details }
    },
})
