
import { defineComponent } from "vue";
import AccoladesCarusel from "@/components/parents/AccoladesCarusel.vue";
import Accolades from "@/components/parents/Accolades.vue";

export default defineComponent({
  components: {
    AccoladesCarusel,
    Accolades,
  },
  setup() {},
});
