<template>
  <div>
    <div
      id="ctl00_ContentPlaceHolderUniverse_AwardsContainer"
      class="column-row column-row-3" style="margin-bottom: 80px;"
    >
      <div class="column column-large first last">
        <div class="column-top"><h1>LEGO Universe Awards</h1></div>
        <div class="column-content">
          <accolades-carusel></accolades-carusel>
        </div>
      </div>
    </div>
    <accolades></accolades>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import AccoladesCarusel from "@/components/parents/AccoladesCarusel.vue";
import Accolades from "@/components/parents/Accolades.vue";

export default defineComponent({
  components: {
    AccoladesCarusel,
    Accolades,
  },
  setup() {},
});
</script>
